export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: " Gain the avenue of great knowledge with the best university selection",
    desc: " Our highly qualified counselors are not only very effective in helping you choose the best colleges, but they also carefully consider every important factor to provide you with the institutions that will help you have an easy and smooth trip through your academic career. ",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: " Select highly influential universities for highly impactful education",
    desc: " Our highly qualified counselors will provide you with the finest advice possible to help you enroll in the top international institute and assist every student in setting wise and clear goals. As a result, it will be simpler to accomplish your research for your course at the institution.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: " University that provides you the effective employment is the one that satisfies you",
    desc: " We will help you to pick a university that would assist you in landing a solid career. if you intend to work and settle in the nation where you are studying. The likelihood of employment after completing the program must be one of your deciding factors while selecting a university. We can assist you in this by letting you know which course will enable you to receive a profitable package after your studies are over.",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESSFUL STUDENTS",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED FACULTY",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "29",
    title: "COUNTRIES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "350",
    title: "ABROAD COURSES",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/india.webp",
    coursesName: "India",
    courTeacher: [
      {
        //  dcover: "./images/back.webp",
        //  name: "by John Smith",
        //  totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "India is known for institutions like the Indian Institutes of Technology (IITs), Indian Institutes of Management (IIMs), Jawaharlal Nehru University, and University of Delhi. Other leading options include University of Mumbai, Savitribai Phule Pune University, Aligarh Muslim University, University of Madras, and University of Calcutta. India's higher education system combines prestigious public universities and private institutions. Our consultants guide you through India's complex admissions process.",
    description:"",
    // pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/usa.png",
    coursesName: "USA",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        // name: "by Ram Gurung",
        // totalTime: "30 lectures (125 hrs)",
      },
    ],
     priceAll: "According to major world rankings, some of the best universities in the USA include schools like: Harvard University, Massachusetts Institute of Technology (MIT), Stanford University, University of California - Berkeley, Yale University, Princeton University, California Institute of Technology (Caltech), University of Chicago, University of Pennsylvania, Cornell University.Our consulting services can help guide you through the process of applying to American colleges and finding the right fit for your academic interests and career aspirations.",
     description:"",
    // pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/canada.png",
    coursesName: "Canada",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        // name: "by Saroj Nepal",
        // totalTime: "50 lectures (50 hrs)",
      },
    ],
     priceAll: "Students from across the globe aspire to study at renowned Canadian institutions like , University of Toronto, University of British Columbia, McGill University, McMaster University, University of Montreal, University of Alberta, University of Waterloo, Western University, Queen's University, Dalhousie University.As an education destination, Canada continues to rise in popularity for international students seeking an alternative to the US and UK. Our expert consulting services provide guidance every step of the way to help you apply successfully to Canadian universities.",
     description:"",
    // pricePer: "$5 per month",
  },
  {
    id: 4,
    cover: "../images/courses/uk.png",
    coursesName: "United Kingdom",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        // name: "by Prabin Thapa Mager",
        // totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "The United Kingdom is home to some of the most prestigious higher education institutions in the world, including the Universities of Oxford, Cambridge, Edinburgh, Imperial College London, University College London. Other leading universities like King’s College London, the University of Manchester, the University of Bristol, the University of Warwick, Durham University, St Andrews University, and the London School of Economics attract top students from across the globe. Navigating admissions to competitive UK universities can be complex and challenging. Our experienced consultants guide you every step of the way.",
    description:"",
    // pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/australia.png",
    coursesName: "Australia",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        // name: "by Sunil Bishowkaram",
        // totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "Australia is globally recognised for its excellent higher education system and research-intensive universities like Australian National University, the University of Melbourne, the University of Sydney, the University of Queensland, and Monash University. Other top-ranked options include the University of New South Wales, the University of Western Australia, the University of Adelaide, Macquarie University and RMIT University. The university admissions process can be complex with timing differences. Our consultants guide you through every detail.",
    description:"",
    // pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/nz.png",
    coursesName: "New Zealand",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        // name: "by Sunil Bishowkaram",
        // totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "New Zealand is recognized for top-ranked universities including the University of Auckland, University of Otago, Victoria University of Wellington, University of Canterbury, Auckland University of Technology, and Massey University. Other excellent options are the University of Waikato, Lincoln University, the University of Queensland, and the University of Technology Sydney.Our consultants guide you through the personalised NZ university admissions process.",
    description:"",
    // pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/ireland.png",
    coursesName: "Ireland",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        // name: "by Price",
        // totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "Ireland is home to esteemed institutions like Trinity College Dublin, University College Dublin, National University of Ireland Galway, University College Cork, and Dublin City University attract students worldwide. Other highly regarded options include Dublin Institute of Technology, Maynooth University, University of Limerick, Queen’s University Belfast, and Technological University Dublin. Applying to competitive Irish universities can be daunting. Our experienced consultants guide you every step of the way.",
    description:"",
    // pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/europe.png",
    coursesName: "Europe",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        // name: "by Petter",
        // totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "Europe is home to some of the oldest and most renowned universities in the world, offering prestigious academics and research. Top options in Europe include University of Oxford, University of Cambridge, ETH Zurich, University of Edinburgh, Imperial College London, King's College London, Sorbonne University, University of Munich, Heidelberg University, and Karolinska Institute. Whether aiming for elite universities in the UK, affordable options in Germany, or respected institutions across Europe, our consultants can help.",
    description:"",
    // pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/germany.png",
    coursesName: "Germany",
    courTeacher: [
      {
        // dcover: "./images/back.webp",
        // name: "by Petter",
        // totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: " Germany has long been a popular destination to study abroad. It has more than 300 institutions of higher education throughout the country, has a fascinating history, and is relatively affordable. Study abroad in Germany and you can learn or improve your German language skills, get international business experience with an internship, or work at a startup. Many students also choose to study abroad in Germany for its central location and ease of transport to neighboring countries. Germany's contributions to western culture through the arts, business, technology, and research still continue to be celebrated today.",
    description:"",
    // pricePer: "$3 per month",
  },
]
export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
]
export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "How do I choose which university to study in when there are so many options in the US/Canada?",
    desc: "Research, then decide what matters most. Check out the university's different scholarships available and the QS world ranking. Verify the accreditation of the institutions you are considering.7 Steps to Find Your University -i) Make sure your passions are clear. ii)	Rank the top US/Canadian universities in your field. iii)	Examine the course syllabus. iv)	Think about the monetary implications v)	Learn about the options for student housing at US/Canada institutions. vi)	Choose your study destination in the US/Canada. vii)	Participate in university fairs, online conferences, or virtual events",
  },
  {
    title: "Is there any age limitation to get masters in a U.S. university?",
    desc: "In general, you have to be at least 16 years of education, with 12 years in school and 4 years for bachelor. Without this, your application will not be considered.",
  },
  {
    title: "What are some of the most common graduate and undergraduate scholarships?",
    desc: "Fulbright Foreign Student Program in the USA, American University Emerging Global Leader Scholarship, AAUW International Fellowships in USA for Women, Fully-Funded Rotary Peace Fellowships, British Council GREAT Scholarship, IELTS Scholarship",
  },
  {
    title: "What are the internship opportunities in Canada?",
    desc: "You can complete an internship in Canada as a part of your studies back home, thanks to the bilateral youth mobility agreements that Canada has with about 35 other nations. This particular type of work permit for an internship is known as an International Co-op or internship visa. It is a part of the well-known International Experience Canada program (IEC).Please be aware that not all nations with which International Experience Canada has agreements have access to this kind of work permit. In these circumstances, another choice could be to apply for a Working Holiday permit. There is only one general prerequisite: you must be enrolled as a student for the whole internship outside of Canada.",
  },
  {
    title: "Is GRE important for my admissions to US applications?",
    desc: "One of the most prestigious entrance tests for graduate school, such as a Ph.D. or MS at an international college or university, is the Graduate Record Examination (GRE).Prospective students are needed to take the GRE exam in order to be accepted into master's programs at ranking colleges in the USA. In the USA, the GRE is a requirement for technical master's degrees such as the MS, MCA, MSC, MTech, and MBA.Admission to the top GRE universities in the USA requires an overall GRE score of 320 or higher and a 4.5 in GRE AWA.",
  },
  {
    title: "Which country is good for MBA?",
    desc: "The US, Germany, Canada, Australia, and France are the best countries for MBA study.",
  },

  {
    title: "Which countries are free to study abroad?",
    desc: "The countries and universities listed here are well-known for providing free education to all foreign students. Check out Study Abroad Without IELTS or TOEFL in Foreign Countries if you're interested in learning more about the prerequisites. •	Germany  •	Sweden  •	Belgium  •	Finland  •	Spain  •	France  •	Norway",
  },

  {
    title: "When and how should I apply for a student visa?",
    desc: "Once you have received confirmation of your enrolment at the university or institution of your choice, you can start the application process for a student visa. Regardless of when your program is scheduled to begin, it is preferable to apply as early as possible because the processing period for visas varies from country to country.When you apply for a student visa, there are a number of measures to take into account. However, depending on the nation in which you wish to study, the sequence of these steps, or the procedures and requirements, may change.The majority of students will want proof of:-	 Enrolment at an accredited college or university. -	Evidence demonstrating your ability to afford the travel, course tuition, and living expenses for you (and your family) throughout your stay year-	Passport-size pictures and a passport that is still valid at least six months after your intended stay are required. -	Some nations can ask you to submit documentation of your English language ability or undergo medical exams and/or police checks.If you have trouble with your application process or VISA process, we will gladly use our best resources to help you reach your dreams. We can provide you with suggestions on how to approach the whole process. We'll make sure you are completely informed on the most recent conditions and requirements for visas and assist you in putting together the necessary paperwork for your application can advise you on how to go about the entire process. We’ll make sure you are fully informed on the latest visa requirements and conditions; also help you prepare the right documents for your submission. To ensure you have access to the most recent application forms and instructions, our team of professionals will point you toward authorized immigration representatives and official websites.",
  },
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
]
