// import { Margin, Padding } from "@mui/icons-material"
import React from "react"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <div className="yo">
              <img src="./images/logowithoutbg.png" alt="logo" width={90} height={90} ></img>  
            <h1>&nbsp;Orinova Overseas</h1>
            </div>
             {/* <span>Study Abroad through Orinova Overseas</span>  */}
            
          </div>

          <div className='social'>
            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-instagram icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-youtube icon'></i>
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
