// import React from "react"
// import Heading from "../../common/heading/Heading"
// import "./Hero.css"

// const Hero = () => {
//   return (
//     <>
//       <section className='hero'>
//         <div className='container'>
//           <div className='row'>
//             <Heading subtitle='Welcome to Orinova Overseas' title='We are the Reflection of Perfection ' />
//             <p>We support you every step of the way. From the day you come to us to the day you set foot in your dream university, we will be with you until the end and at all costs!</p>
//             <div className='button'>
//               <button className='primary-btn'>
//                 GET STARTED NOW <i className='fa fa-long-arrow-alt-right'></i>
//               </button>
//               <button>
//                 VIEW COUNTRIES <i className='fa fa-long-arrow-alt-right'></i>
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>
//       <div className='margin'></div>
//     </>
//   )
// }

// export default Hero




import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Heading subtitle='Welcome to Orinova Overseas' title='We are the Reflection of Perfection ' />
            <p>We support you every step of the way. From the day you come to us to the day you set foot in your dream university, we will be with you until the end and at all costs!</p>
            <div className='button'>
              <button className='primary-btn'>
                GET STARTED NOW <i className='fa fa-long-arrow-alt-right'></i>
              </button>
              <button>
                VIEW COUNTRIES <i className='fa fa-long-arrow-alt-right'></i>
              </button>
            </div>
          </div>
        </div>
        <div class="slider">
    <div class="slide"></div>
    <div class="slide"></div>
    <div class="slide"></div>
</div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero